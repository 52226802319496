<template>
  <v-row>
    <v-col md="2">
      <span class="bold small-text">Nº solicitação</span>
      <p>{{ requestNumber }}</p>
    </v-col>
    <v-col md="2">
      <span class="bold small-text">Data solicitação</span>
      <p>{{ requestDate }}</p>
    </v-col>
    <v-col md="2">
      <span class="bold small-text">Situação da solicitação</span>
      <p>{{ requestSituation }}<request-pendencies-dialog v-if="withPendency"></request-pendencies-dialog></p>
    </v-col>
    <v-col md="2">
      <span class="bold small-text">Tipo de serviço</span>
      <p>{{ requestServiceTypeText }}</p>
    </v-col>
    <v-col md="2">
      <span class="bold small-text">Atendente</span>
      <p>
        {{ requestAttendant }}
        <new-request-select-attendant v-if='isRealEstateUser && inRegistration'></new-request-select-attendant>
      </p>
    </v-col>
    <v-col v-if="availableToReopen">
      <request-reopen-to-analyse v-if="isGSuser && availableToReopen"></request-reopen-to-analyse>
    </v-col>
    <v-col v-else align="center">
      <request-change-situation v-if="isGSuser && inAnalysis"></request-change-situation>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import NewRequestSelectAttendant from './SelectAttendant'
import RequestChangeSituation from './ChangeSituation'
import RequestPendenciesDialog from './PendenciesDialog'
import RequestReopenToAnalyse from './ReopenDialog'

export default {
  name: 'RequestInfosBar',
  components: {
    NewRequestSelectAttendant,
    RequestChangeSituation,
    RequestPendenciesDialog,
    RequestReopenToAnalyse
  },
  data () {
    return {
      //
    }
  },
  computed: {
    ...mapGetters([
      'requestNumber',
      'requestDate',
      'requestSituation',
      'requestServiceTypeText',
      'requestAttendant',
      'isRealEstateUser',
      'isGSuser',
      'inAnalysis',
      'inRegistration',
      'withPendency',
      'availableToReopen'
    ])
  }
}
</script>

<style scoped>
h5 {
  font-weight: bold;
}
</style>
