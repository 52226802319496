<template>
  <v-form v-model="valid">
    <h5 class="primary--text bold" v-if="title">Observações da locação</h5>
    <h6 v-if="title" class="text_dark_grey">Conte-nos alguma informação relevante sobre essa locação e seus pretendentes.</h6>
    <v-container class="ml-0 pl-0">
      <v-row>
        <v-col>
          <v-textarea
            v-model="observacao"
            label="Observação"
            auto-grow
            rows="1"
            :readonly=readonly
          ></v-textarea>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'NewRequestObservation',
  props: {
    title: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    valid: false
  }),
  computed: {
    ...mapFields([
      'request.observacao'
    ])
  }
}
</script>
