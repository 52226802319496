<template>
    <v-dialog v-model="dialog" max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn text icon v-bind="attrs" v-on="on" title="Trocar atendente">
            <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-select
                  no-data-text="Nenhum dado encontrado"
                  v-model=usuario_id
                  :items="op_attendants"
                  label="Atendente"
                  @change="selectAttendant"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import { mapFields } from "vuex-map-fields";

export default {
  name: "NewRequestSelectAttendant",
  data: () => ({
    selected: null,
    dialog: false,
  }),
  computed: {
    ...mapFields([
      "request.usuario_id",
    ]),
    ...mapGetters(["attendantsList", "activeUser"]),
    op_attendants() {
      return this.attendantsList;
    },
  },
  created() {
    if(this.notEmpty(this.usuario_id)) {
      this.usuario_id = this.activeUser.id
    }
  },
  methods: {
    ...mapMutations([
      'setRequestAttendant',
    ]),
    selectAttendant(val) {
      this.dialog = false;
    }
  },
};
</script>
