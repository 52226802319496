<template>
  <v-dialog v-model="dialog" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on" title="Excluir">
        <v-icon :small="btnStyle.small" :color="btnStyle.color"> mdi-delete </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-text class="pl-2 pr-2 pt-1 pb-1">
        <v-container>
          <v-row>
            <v-col class="pa-0 ma-0">
              <h5 class="bold" v-html=text></h5>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color=""
          @click="dialog = false"
          rounded
        >
          Cancelar
        </v-btn>
        <v-btn
          color="error"
          @click=deleteRegister
          :loading=loading
          rounded
        >
          Excluir
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DeleteItemDialog',
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    text: {
      type: String,
      required: false,
      default: 'Tem certeza de que deseja excluir esse registro? <br>Esta ação não poderá ser desfeita!'
    },
    btnStyle: {
      type: Object,
      required: false,
      default: {
        small: false,
        color: 'error'
      }
    }
  },
  data: () => ({
    dialog: false
  }),
  watch: {
    loading (val) {
      if (val === false) {
        this.dialog = false
      }
    }
  },
  methods: {
    deleteRegister () {
      this.$emit('delete')
    }
  }
}
</script>
