<template>
  <v-dialog v-model="dialog" max-width="850px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        rounded
        color="primary"
        title="Reabrir solicitação para análise"
        v-bind="attrs"
        v-on="on"
      >
        Reabrir
        <v-icon>mdi mdi-share</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            <h5 class="text_error">Reabrir Solicitação</h5>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text align="center" justify="center">
        <v-container>
          <v-progress-circular
            :size="70"
            :width="7"
            color="primary"
            indeterminate
            v-if=loading
          ></v-progress-circular>
          <v-row v-else>
            <v-col>
              <h5>Deseja alterar o status da solicitação <strong>Nº {{ requestNumber }}</strong> para Análise? </h5>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closeDialog" rounded :disabled=loading>
          Cancelar
        </v-btn>
        <v-btn color="primary" @click="changeSituationToAnalyse" rounded :disabled=loading>
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'RequestReopenToAnalyse',
  data: () => ({
    dialog: false,
    loading: false
  }),
  computed: {
    ...mapGetters([
      'requestNumber'
    ])
  },
  methods: {
    ...mapActions([
      'changeSituation'
    ]),
    closeDialog () {
      this.dialog = false
    },
    async changeSituationToAnalyse () {
      this.loading = true
      const situationAnalyseId = 2
      await this.changeSituation(situationAnalyseId).then(() => {
        this.loading = false
      })
        .catch(err => {
          this.$toastr.e(err.response.data.message)
        })
        .finally(() => {
          this.loading = false
        })
      this.closeDialog()
      this.$parent.$emit('situation-changed')
    }
  }
}

</script>
