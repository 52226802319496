<template>
  <v-container>
    <v-row>
      <v-col md="4">
        <MoneyField
          label="Outras rendas"
          v-model="outras_rendas[formId]"
          :readonly=readonly
          :disabled=makesUpIncome
        ></MoneyField>
      </v-col>
      <v-col md="4">
        <v-select
          no-data-text="Nenhum dado encontrado"
          item-text="descricao"
          item-value="id"
          v-model="origem_outras_rendas[formId]"
          :items="op_origem_renda"
          label="Origem da renda"
          :readonly=readonly
          :disabled=makesUpIncome
        ></v-select>
      </v-col>
      <v-col>
        <v-icon
          @click="deleteItem"
          class="mt-5"
          color="error"
          v-if="!readonly && showDelete"
        >
          mdi-delete
        </v-icon>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { mapFields } from 'vuex-map-fields'

export default {
  name: "FinancialProfileOtherIncome",
  props: {
    formId: {
      type: Number,
      required: true,
    },
    showDelete: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapFields([
      'newTenant.financialProfile.outras_rendas',
      'newTenant.financialProfile.origem_outras_rendas',
    ]),
    ...mapGetters(["incomeSourceList", "makesUpIncome"]),
    op_origem_renda() {
      return this.incomeSourceList;
    },
  },
  created() {
    this.getIncomeSourceList();
  },
  methods: {
    ...mapActions(["getIncomeSourceList"]),
    deleteItem() {
      this.$emit("delete", this.formId);
    },
  },
};
</script>
