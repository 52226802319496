<template>
  <v-dialog v-model="dialog" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="error"
        text
        x-small
        v-bind="attrs"
        v-on="on"
      >
        Cancelar solicitação
      </v-btn>
    </template>
    <v-card>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-textarea
                v-model="form.justificativa"
                label="Justificativa"
                hint="* Informe o motivo do cancelamento"
                persistent-hint
                auto-grow
                rows="1"
                required
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color=""
          @click="dialog = false"
          rounded
        >
          Fechar
        </v-btn>
        <v-btn
          color="primary"
          @click=setCancelSituation
          :loading=loading
          rounded
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'RequestCancel',
  data: () => ({
    dialog: false,
    loading: false,
    form: {
      justificativa: ''
    }
  }),
  methods: {
    ...mapActions(['cancelSolicitation']),
    setCancelSituation () {
      this.loading = true
      this.cancelSolicitation(this.form.justificativa)
        .then(() => {
          this.$router.push('/solicitacoes')
          this.dialog = false
        })
        .catch(err => {
          this.$toastr.e(err.response.data.message)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
