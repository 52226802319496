<template>
  <v-container>
    <v-row>
      <v-col md="2">
        <v-text-field
          v-model="cpf"
          v-mask="'###.###.###-##'"
          label="CPF*"
          @blur="searchPerson(readonly)"
          :readonly=readonly
          :rules="[rules.required, rules.validCPF]"
        ></v-text-field>
      </v-col>
      <v-col md="6">
        <v-text-field
          v-model="nome"
          label="Nome completo"
          required
          :loading=loadingPerson
          :readonly=readonly
        ></v-text-field>
      </v-col>
      <v-col md="2">
        <v-select
          no-data-text="Nenhum dado encontrado"
          item-text="nome"
          item-value="id"
          v-model="tipo_documento_complementar_id"
          :items="op_doc_complementar"
          label="Documento complementar"
          :loading=loadingPerson
          :readonly=readonly
        ></v-select>
      </v-col>
      <v-col md="2">
        <v-text-field
          v-model="numero_doc_complementar"
          label="Número doc. complementar"
          :loading=loadingPerson
          :readonly=readonly
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="2">
        <DatePicker label="Data de nascimento" v-model="data_nascimento" :loading=loadingPerson :readonly=readonly></DatePicker>
      </v-col>
      <v-col md="3">
        <v-text-field
          v-model="profissao"
          label="Profissão"
          :loading=loadingPerson
          :readonly=readonly
        ></v-text-field>
      </v-col>
      <v-col md="3">
        <v-select
          no-data-text="Nenhum dado encontrado"
          item-text="descricao"
          item-value="id"
          v-model="estado_civil_id"
          :items="op_estado_civil"
          label="Estado civil"
          :loading=loadingPerson
          :readonly=readonly
        ></v-select>
      </v-col>
      <v-col md="4">
        <v-select
          no-data-text="Nenhum dado encontrado"
          item-text="descricao"
          item-value="id"
          v-model="pretensao_id"
          :items="op_pretendente_a"
          label="Pretendente à"
          :loading=loadingPerson
          :readonly=readonly
        ></v-select>
        <v-switch
          v-model="vai_morar"
          label="Vai morar no imóvel"
          class="ma-0"
          :loading=loadingPerson
          :readonly=readonly
          ></v-switch>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col md="2">
        <v-autocomplete
          no-data-text="Nenhum dado encontrado"
          item-text="descricao"
          item-value="id"
          v-model="id_nacionalidade"
          :items="nacionalidades"
          label="Nacionalidade"
          :loading=loadingPerson
          :readonly=readonly
        ></v-autocomplete>
      </v-col>
      <v-col md="3">
        <v-text-field
          v-model="email"
          label="E-mail"
          required
          :loading=loadingPerson
          :readonly=readonly
        ></v-text-field>
      </v-col>
      <v-col md="2">
        <v-text-field
          v-model="telefone"
          v-mask="'(##) ####-#####'"
          label="Telefone"
          :loading=loadingPerson
          :readonly=readonly
        ></v-text-field>
      </v-col>
      <v-col md="5">
        <v-text-field
          v-model="observacoes"
          label="Observações"
          :loading=loadingPerson
          :readonly=readonly
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import isValidCPF from '@/methods/isValidCPF'

export default {
  name: 'PersonalDataFormTenant',
  props: {
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    rules: {
      required: value => !!value || 'Campo obrigatório',
      validCPF: value => isValidCPF(value) || 'Informe um CPF válido'
    },
    cpfSearched: null,
    loadingPerson: false
  }),
  computed: {
    ...mapFields([
      'newTenant.personalData.nome',
      'newTenant.personalData.cpf',
      'newTenant.personalData.tipo_documento_complementar_id',
      'newTenant.personalData.numero_doc_complementar',
      'newTenant.personalData.data_nascimento',
      'newTenant.personalData.profissao',
      'newTenant.personalData.estado_civil_id',
      'newTenant.personalData.pretensao_id',
      'newTenant.personalData.email',
      'newTenant.personalData.telefone',
      'newTenant.personalData.observacoes',
      'newTenant.personalData.vai_morar',
      'newTenant.personalData.id_nacionalidade'
    ]),
    ...mapGetters([
      'maritalStatusList',
      'pretensionSolicitationList',
      'complementaryDocumentTypeList',
      'nacionalidadeList'
    ]),
    op_estado_civil () {
      return this.maritalStatusList
    },
    op_pretendente_a () {
      return this.pretensionSolicitationList.filter((i) => i.is_desativado == false)
    },
    op_doc_complementar () {
      return this.complementaryDocumentTypeList
    },
    is_valid_cpf () {
      return isValidCPF(this.cpf)
    },
    nacionalidades () {
      return this.nacionalidadeList
    }
  },
  created () {
    this.getMaritalStatusList()
    this.getPretensionSolicitationList()
    this.getComplementaryDocumentTypeList()
    this.getNacionalidadeList()
  },
  watch: {
    data_nascimento (val) {
      if (this.getAge(val) < 16) {
        this.$toastr.e('O locatário possui menos de 16 anos, favor verificar o cadastro')
      } else if (this.getAge(val) < 18) {
        this.$toastr.w('O locatário possui menos de 18 anos, deverá ser inserido o documento de emancipação')
      }
    }
  },
  methods: {
    ...mapActions([
      'getMaritalStatusList',
      'getPretensionSolicitationList',
      'getPerson',
      'getComplementaryDocumentTypeList',
      'getNacionalidadeList'
    ]),
    searchPerson (readonly = false) {
      if (!readonly && this.is_valid_cpf && this.cpfSearched != this.cpf) {
        this.cpfSearched = this.cpf
        this.loadingPerson = true
        this.getPerson(this.cpf).then(resp => {
          if (resp.length !== 0) {
            this.nome = resp.nome
            this.tipo_documento_complementar_id = resp.tipo_documento_complementar_id
            this.numero_doc_complementar = resp.numero_doc_complementar
            this.data_nascimento = resp.data_nascimento
            this.profissao = resp.profissao
            this.estado_civil_id = resp.estado_civil_id
            this.email = resp.email
            this.telefone = resp.telefone
            this.observacoes = resp.observacoes
            this.nacionalidade = resp.nacionalidade
          }
        })
          .catch(err => {
            this.$toastr.e(err.response.data.message)
          })
          .finally(() => {
            this.loadingPerson = false
          })
      }
    },
    getAge (dateString) {
      var today = new Date()
      var birthDate = new Date(dateString)
      var age = today.getFullYear() - birthDate.getFullYear()
      var m = today.getMonth() - birthDate.getMonth()
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--
      }
      return age
    }
  }
}
</script>
