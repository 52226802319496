<template>
  <v-dialog v-model="dialog" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        rounded
        small
        class="mt-3"
        v-bind="attrs"
        v-on="on"
      >
        Alterar situação
      </v-btn>
    </template>
    <v-card>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-select
                no-data-text="Nenhum dado encontrado"
                v-model="form.situacao"
                :items="situations"
                label="Situação"
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color=""
          @click="dialog = false"
          rounded
        >
          Fechar
        </v-btn>
        <v-btn
          color="primary"
          @click=selectSituation
          rounded
          :disabled="!form.situacao"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'RequestChangeSituation',
  data: () => ({
    dialog: false,
    form: {
      situacao: ''
    },
    situations: [
      {
        value: 3,
        text: 'Existem pendências'
      },
      {
        value: 4,
        text: 'Aprovada'
      },
      {
        value: 5,
        text: 'Rejeitada'
      }
    ]
  }),
  methods: {
    ...mapActions(['changeSituation']),
    selectSituation () {
      this.changeSituation(this.form.situacao).then(() => {
        this.$router.push('/solicitacoes')
      })
      this.dialog = false
    }
  }
}
</script>
