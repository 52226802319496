<template>
  <v-container>
    <v-row>
      <v-col md="3">
        <v-switch
          v-model="ja_cadastrado"
          label="Utilizar endereço já cadastrado"
          :loading="loadingAddress"
          :readonly=readonly
        ></v-switch>
      </v-col>
      <v-col md="4">
        <v-select
          no-data-text="Nenhum dado encontrado"
          item-text="personalData.nome"
          item-value="address"
          v-model="locatario"
          :items="op_locatarios"
          label="Locatário"
          @change="selectTenant"
          :loading="loadingAddress"
          :readonly=readonly
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="3" class="align-self-md-center pa-6">
        <v-row>
          <v-text-field
            v-model="cep"
            v-mask="'#####-###'"
            label="CEP"
            :loading="loadingAddress"
            :readonly=readonly
          ></v-text-field>
          <v-btn
            title="Consultar CEP"
            outlined
            class="pa-0 mt-3 ml-2"
            rounded
            color="primary"
            @click="getAddress"
            :loading="loadingAddress"
            v-if=!readonly
          >
            <v-icon>mdi-magnify</v-icon>
            CEP
          </v-btn>
        </v-row>
      </v-col>
      <v-col md="4">
        <v-text-field
          v-model="logradouro"
          label="Endereço"
          :loading="loadingAddress"
          :readonly=readonly
        ></v-text-field>
      </v-col>
      <v-col md="2">
        <v-text-field
          v-model="numero"
          label="Número"
          :loading="loadingAddress"
          :readonly=readonly
        ></v-text-field>
      </v-col>
      <v-col md="3">
        <v-text-field
          v-model="complemento"
          label="Complemento"
          :loading="loadingAddress"
          :readonly=readonly
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="4">
        <v-text-field
          v-model="bairro"
          label="Bairro"
          :loading="loadingAddress"
          :readonly=readonly
        ></v-text-field>
      </v-col>
      <v-col md="4">
        <v-select
          no-data-text="Nenhum dado encontrado"
          item-text="nome"
          item-value="id"
          v-model="estado_id"
          :items="op_estados"
          label="Estado"
          @change="getCidades"
          :loading="loadingAddress"
          :readonly=readonly
        ></v-select>
      </v-col>
      <v-col md="4">
        <v-select
          no-data-text="Nenhum dado encontrado"
          item-text="nome"
          item-value="id"
          v-model="cidade_id"
          :items="op_cidades"
          label="Cidade"
          :loading="loadingAddress || loadingCidades"
          :readonly=readonly
        ></v-select>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'AddressFormTenant',
  props: {
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loadingCidades: false,
    loadingAddress: false
  }),
  computed: {
    ...mapFields([
      'newTenant.address.ja_cadastrado',
      'newTenant.address.locatario',
      'newTenant.address.cep',
      'newTenant.address.logradouro',
      'newTenant.address.numero',
      'newTenant.address.complemento',
      'newTenant.address.bairro',
      'newTenant.address.estado_id',
      'newTenant.address.cidade_id'
    ]),
    ...mapGetters(['statesList', 'citiesList', 'tenants']),
    op_estados () {
      return this.statesList
    },
    op_cidades () {
      return this.citiesList
    },
    op_locatarios () {
      return this.tenants.filter(t => {
        return this.notEmpty(t.personalData.nome)
      })
    }
  },
  created () {
    this.getStatesList().then(() => {
      if (this.estado_id) {
        this.getCidades(this.estado_id)
      }
    })
  },
  methods: {
    ...mapActions(['getStatesList', 'getCitiesList', 'getAdrressByCep']),
    getCidades (val) {
      this.loadingCidades = true
      this.getCitiesList(val).then(() => {
        this.loadingCidades = false
      })
    },
    selectTenant (val) {
      if (this.ja_cadastrado) {
        this.cep = val.cep
        this.logradouro = val.logradouro
        this.numero = val.numero
        this.complemento = val.complemento
        this.bairro = val.bairro
        this.estado_id = val.estado_id
        this.cidade_id = val.cidade_id
      }
    },
    getAddress () {
      this.loadingAddress = true
      this.getAdrressByCep(this.cep)
        .then((resp) => {
          this.bairro = resp.bairro
          this.bairro = resp.bairro
          this.logradouro = resp.logradouro
          this.estado_id = this.statesList.find((state) => {
            return state.uf.substring(0, 2) == resp.uf
          }).id
          this.loadingCidades = true
          this.getCitiesList(this.estado_id).then(() => {
            this.cidade_id = this.citiesList.find((city) => {
              return resp.ibge.substring(0, 6) == city.ibge
            }).id
            this.loadingCidades = false
          })
        })
        .catch(err => {
          this.$toastr.e(err.response.data.message)
        })
        .finally(() => {
          this.loadingAddress = false
        })
    }
  }
}
</script>
